import {withStylesParams} from '../../../stylesParamsContext';
import stylesParams from '../../GridGallery/stylesParams';
import {WishlistApp} from '../../../wishlist/components/WishlistApp/WishlistApp';
import React from 'react';
import {initContext, withGlobalsProvider} from '../../../globalPropsContext';
import {IPropsInjectedByViewerScript} from '../../../types/sliderGalleryTypes';
import {IGallerySantaProps} from '../../../types/galleryTypes';

export function bootstrapWishlist(Component: React.ComponentType) {
  const strategy = (props: IPropsInjectedByViewerScript & IGallerySantaProps) => ({
    globals: {...props},
    locals: {...props},
  });
  initContext(strategy);
  const ComponentWithGlobals = withGlobalsProvider(Component, false);
  return withStylesParams(ComponentWithGlobals, stylesParams);
}

export default bootstrapWishlist(WishlistApp);
